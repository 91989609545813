import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCalendly: false,
        }
    };

    componentDidMount() {
        setTimeout(() => this.setState({ isCalendly: true }), 5000);
    };

    render() {

        let { isCalendly } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/contact-us/"
                        title="Unleashing The True Potential Of Blockchain Space - Softtik"
                        description="Softtik, one of the world's pioneering blockchain development companies harnesses power of blockchain & crypto to build scalable, asset-agnostic applications."
                    />
                    <main>
                        <div id="wrapper">
                            <div className="contactus-page">
                                <section className='contact-information-sec'>
                                    <div className='get-intouch-secs'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='form-box col-lg-6 col-md-12'>
                                                    <div className='form-inner' id="get-intouch">
                                                        <div className='sec-title text-center'>
                                                            <h2>How Can We Help You?</h2>
                                                            <p>Get in touch and our expert support team will answer all your questions.</p>
                                                        </div>
                                                        <div className='form-area'>
                                                            <iframe src="https://contact-softtik.gr8.com/"></iframe>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='calendly-box col-lg-6 col-md-12'>
                                                    <div className='calendly-inner'>
                                                        <div className='sec-title text-center'>
                                                            <h2>Can’t Wait Enough?</h2>
                                                            <p>Book a Free Consultation with Our Experts Right Away</p>
                                                        </div>
                                                        {isCalendly &&
                                                            <div className='calendly-area'>
                                                                <iframe className="calendly-inline-widget" src="https://calendly.com/softtik-technologies?hide_gdpr_banner=1" loading="lazy" width="520px" height="630px"></iframe>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='contact-information-box col-lg-6 offset-lg-3 col-md-12'>
                                                <div className='contact-information-inner'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Talk to Us</h2>
                                                        <p>Talk to us on the phone or via email</p>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <a href='tel:+16186634546'>
                                                                <i className='fa fa-phone icon'></i>
                                                                +1 (618) 663-4546</a>
                                                        </li>
                                                        <li><a className='active' href='mailto:info@softtik.com'>
                                                            <i className='fa fa-envelope icon'></i>
                                                            info@softtik.com</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='map-sec'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='map-box col-12'>
                                                <div className='map-inner'>
                                                    <Tabs orientation="horizontal">
                                                        <TabList>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/ae-flag.webp' alt="flag" /></span>Dubai Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag-1.webp' alt="flag" /></span>California Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag.webp' alt="flag" /></span>Rawalpindi Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag.webp' alt="flag" /></span>Lahore Office</Tab>
                                                        </TabList>
                                                        <TabPanels>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab Emirates</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225.71298470101962!2d55.30051589970127!3d25.155706148181615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69c9d616266d%3A0xb3fb9c63ffc5370c!2sSoulful!5e0!3m2!1sen!2s!4v1666784109492!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>1765 Landess Ave #546 Milpitas CA 95035</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.829332791597!2d-121.87463648520142!3d37.41750864040746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fce93e595cf69%3A0xa661a4b970fcd129!2s1765%20Landess%20Ave%20%23546%2C%20Milpitas%2C%20CA%2095035%2C%20USA!5e0!3m2!1sen!2s!4v1655708554574!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>Unit# D, Floor# 2, Plot# 68, Box Park Building, Bahria Springs North, Phase 7, Rawalpindi</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.0080569168867!2d73.09579691468467!3d33.52717615266498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfede8cfd2c96b%3A0xa614c2b01ad71be4!2sSofttik%20Technologies!5e0!3m2!1sen!2s!4v1655705960129!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>Hafeez Executive Plaza, Block C3, Gulberg III, Near Firdous Market, 10th Floor Office# 1020</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.7521291739786!2d74.34579431463044!3d31.503496355128256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919044f43c8299b%3A0xd7cd5ca5cfb65db9!2sAl%20Hafeez%20Executive!5e0!3m2!1sen!2s!4v1655708631071!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </TabPanels>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>

                </InnerLayout >
            </>
        )
    }
};

export default ContactUs;